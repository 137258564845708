import * as React from "react";
import {
  makeStyles,
  shorthands,
  Button,
  Caption1,
  tokens,
  Text,
  Image,
  SkeletonItem,
  mergeClasses,
  teamsDarkTheme,
} from "@fluentui/react-components";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";

import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import AgentIcon from "../AgentIcon/AgentIcon";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLike, removeLike } from "../../store/items/likes";
import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../hooks/useFirebase";
import { setShowModal } from "../../store/items/user";
import { MdCollectionsBookmark } from "react-icons/md";
import { setShowAddToCollectionModal } from "../../store/items/things";

const useStyles = makeStyles({
  card: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "200px",
    maxWidth: "250px",
    maxHeight: "300px",
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorNeutralStroke2),
    ...shorthands.borderRadius("5px"),
    ":hover": {
      backgroundColor: teamsDarkTheme.colorNeutralBackground3Hover,
      ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandStroke1),
    },
    "@media (max-width: 850px)": {
      maxWidth: "45%",
    },
  },
  caption: {
    color: tokens.colorNeutralForeground3,
    textOverflow: "ellipsis",
  },
  image: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
    width: "100%",
    height: "100%",
    objectFit: "contain",
    backgroundColor: tokens.colorNeutralBackground1,
    aspectRatio: "3 / 2 auto",
  },
  grayBackground: {
    position: "relative",
    backgroundColor: tokens.colorNeutralBackground3,
  },
  logoBadge: {
    ...shorthands.padding("5px"),
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
    backgroundColor: "#FFF",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  },
  bookmark: {
    ":hover": {
      color: tokens.colorBrandForeground1,
    },
  },
  savedBookmark: {
    color: tokens.colorBrandForeground1,
  },
  loadingHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.gap("4px"),
  },
  loadingText1: {
    width: "75%",
  },
  loadingText2: {
    width: "50%",
  },
  bookmarkBtns: {
    alignItems: "center",
    display: "flex",
    flex: 0,
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
});
const LoadingThing = React.memo(({ agent, isRelated }) => {
  const styles = useStyles();
  return (
    <Card
      className={styles.card}
      // style={isRelated ? { maxWidth: "300px" } : {}}
    >
      <CardPreview className={styles.grayBackground}>
        <SkeletonItem className={styles.image} />
      </CardPreview>

      <CardHeader
        header={
          <div className={styles.loadingHeader}>
            <SkeletonItem className={styles.loadingText1} />
            <SkeletonItem className={styles.loadingText2} />
          </div>
        }
        action={
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button
              appearance="transparent"
              icon={<MdCollectionsBookmark />}
              aria-label="More actions"
            />
            <Button
              appearance="transparent"
              icon={<BsBookmark />}
              aria-label="More actions"
            />
          </div>
        }
      />
    </Card>
  );
});

const ThingCard = ({ thing, agent, methods, loading, isRelated = false }) => {
  const likes = useSelector((state) => state.likes.likesArr);
  const user = useSelector((state) => state.user.value);

  const [imgSource, setImgSource] = React.useState(thing.goFinal_preview_image);
  const [imgLoading, setImgLoading] = React.useState(true);

  const dispatch = useDispatch();
  const styles = useStyles();

  const handleNoUser = useCallback(() => {
    dispatch(setShowModal(true));
  }, [dispatch]);

  const handleClick = useCallback(() => {
    methods.setSelectedThing(thing);
  }, [methods, thing]);

  const isSaved = React.useMemo(() => {
    return likes.filter((like) => {
      if (!thing) return false;
      const newTest =
        like.goFinal_id === thing.goFinal_id &&
        like.supplier === thing.goFinal_agent;
      const oldTest =
        like.id &&
        thing.id &&
        String(like.id) === String(thing.id) &&
        like.supplier === thing.goFinal_agent;
      return newTest || oldTest;
    })[0];
  }, [likes, thing]);

  const [isSavedState, setIsSavedState] = React.useState(Boolean(isSaved));

  React.useEffect(() => {
    setIsSavedState(Boolean(isSaved));
  }, [isSaved]);

  const toggleLike = useCallback(
    async (e) => {
      e.stopPropagation();
      if (!user) {
        handleNoUser();
        return;
      }
      const originalState = isSavedState;
      setIsSavedState(!originalState);
      try {
        if (isSaved) {
          await deleteDoc(doc(db, "Likes", isSaved.uid)).then((resp) => {
            dispatch(removeLike(isSaved));
          });
        } else {
          const likeObj = {
            ...thing,
            userId: user.uid,
            supplier: thing.goFinal_agent,
            date_saved_3dgo: new Date(),
          };
          likeObj.uid && delete likeObj.uid;
          await addDoc(collection(db, "Likes"), likeObj).then((resp) => {
            dispatch(addLike({ ...likeObj, uid: resp.id }));
          });
        }
      } catch (e) {
        setIsSavedState(originalState);
      }
    },
    [dispatch, handleNoUser, isSaved, isSavedState, thing, user]
  );

  const RenderBookmark = () => {
    return isSavedState ? (
      <BsBookmarkFill
        className={mergeClasses(
          styles.bookmark,
          isSaved && styles.savedBookmark
        )}
      />
    ) : (
      <BsBookmark
        className={mergeClasses(
          styles.bookmark,
          isSaved && styles.savedBookmark
        )}
      />
    );
  };

  React.useEffect(() => {
    if (!thing) return;
    if (imgSource === thing.goFinal_preview_image) return;
    if (imgSource === thing.goFinal_fallback_image && imgSource) return;

    setImgLoading(true);
    setImgSource(thing.goFinal_preview_image);
  }, [agent, imgSource, thing]);

  const onLoad = useCallback(() => {
    setImgLoading(false);
  }, []);

  const onError = useCallback(() => {
    if (imgSource === thing.goFinal_fallback_image && imgSource) {
      return;
    } else {
      setImgSource(thing.goFinal_fallback_image);
    }
  }, [imgSource, thing]);

  const openAddToCollection = useCallback(
    (e) => {
      e.stopPropagation();
      if (!user) {
        handleNoUser();
        return;
      }
      dispatch(setShowAddToCollectionModal(thing));
    },
    [dispatch, handleNoUser, thing, user]
  );

  if (loading) return <LoadingThing isRelated={isRelated} />;
  return (
    <div
      className={styles.card}
      onClick={handleClick}
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {/* IMAGE */}
      <div className={styles.grayBackground}>
        <div className={styles.image} style={{ position: "relative" }}>
          {imgLoading && (
            <SkeletonItem
              className={styles.image}
              style={{ position: "absolute" }}
            />
          )}
          <Image
            className={styles.image}
            alt={`Free 3D Printer File - ${thing.goFinal_name}`}
            title={`Free 3D Printer Files ${thing.goFinal_name}`}
            src={imgSource}
            onLoad={onLoad}
            onError={onError}
            decoding="async"
            loading="eager"
          />
        </div>
        {/* <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            bottom: "0px",
            width: "100%",
            padding: "10px",
            left: "0px",
          }}
          id="agent-icon"
        >
          <AgentIcon agent={agent} />
        </div> */}
      </div>
      {/* LOWER */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          alignItems: "center",
          flex: 1,
          flexGrow: 1,
          backgroundColor: teamsDarkTheme.colorNeutralBackground3,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: "5px",
          }}
        >
          <p
            style={{
              whiteSpace: "wrap",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
              wordBreak: "break-word",
            }}
          >
            {thing.goFinal_name}
          </p>
          <Caption1
            className={styles.caption}
            wrap
            ellipsis
            truncate
            style={{
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              whiteSpace: "wrap",
              wordBreak: "break-word",
            }}
          >
            <Image
              src={thing.goFinal_creator.thumbnail}
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "100%",
              }}
            />
            {thing?.goFinal_creator?.name}
          </Caption1>
        </div>
        <div className={styles.bookmarkBtns}>
          <Button
            appearance="transparent"
            icon={<MdCollectionsBookmark />}
            aria-label="More actions"
            onClick={openAddToCollection}
          />
          <Button
            appearance="transparent"
            icon={<RenderBookmark />}
            aria-label="More actions"
            onClick={toggleLike}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ThingCard);
